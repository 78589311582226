.close-icon {
    cursor: pointer;
    padding-top: 5px;
}

.close {
    text-align: end;
}

.title-text {
    text-align: end;
    padding-right: 50px !important;
}

.logo-dark img {
    height: 70px;
    width: 100px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.logo-fold {
    height: 50px !important;
    width: 70px !important;
    padding-top: 10px;
    padding-left: 10px;
}

@media (max-width:992px) {
    .title-text {
        text-align: center;
    }
}

@media (max-width:425px) {
    .title-text {
        text-align: start;
    }
}

@media(max-width:991px) {
    .add-button-section a {
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 10;
        /* round design */
        border-radius: 70px;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .add-button-section a span {
        display: none;
    }
    .add-button-section a i {
        font-size: 24px;
        height: 24px;
    }
}