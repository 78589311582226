.product-info-table {

    width: 100%;

    tr {
       &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }

        td {
            padding: 15px;

            &:first-child {

                @include screen-mobile-above {
                    width: 200px;
                }
            }
        }
    }
}

.product-color {
    line-height: $line-height-base;
    display: inline-flex;
    align-items: center;

    .ant-badge {
        line-height: $line-height-base;

        .ant-badge-status-dot {
            width: 15px;
            height: 15px;
        }

        .ant-badge-status-text {
            margin-left: 5px;
        }
    }
}