/**
* prism.js default theme for JavaScript, CSS and HTML
* Based on dabblet (http://dabblet.com)
* @author Lea Verou
*/

pre code {
    display: block;
    padding: 16px 32px;
    color: $body-color;
    font-size: $font-size-base;
    font-family: 'Lucida Console', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    line-height: 2;
    white-space: pre;
    background: white;
    border: 1px solid #e9e9e9;
    border-radius: $border-radius;
  }
  
  code[class*='language-'],
  pre[class*='language-'] {
    color: black;
    font-family: 'Lucida Console', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    line-height: 1.5;
    direction: ltr;
    white-space: pre;
    text-align: left;
    word-wrap: normal;
    word-break: normal;
    word-spacing: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    background: none;
  }
  
  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
  
  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
  
  @media print {
    code[class*='language-'],
    pre[class*='language-'] {
      text-shadow: none;
    }
  }
  
  /* Code blocks */
  pre[class*='language-'] {
    margin: 16px 0;
    padding: 12px 20px;
    overflow: auto;
  }
  
  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: #f5f5f5;
  }
  
  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    white-space: normal;
    border-radius: 0.3em;
  }
  
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: slategray;
  }
  
  .token.punctuation {
    color: #999;
  }
  
  .namespace {
    opacity: 0.7;
  }
  
  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #f81d22;
  }
  
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #0b8235;
  }
  
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #0b8235;
  }
  
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #008dff;
  }
  
  .token.function {
    color: #f81d22;
  }
  
  .token.regex,
  .token.important,
  .token.variable {
    color: #e90;
  }
  
  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }
  
  .token.entity {
    cursor: help;
  }
  